import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import ArticleModel from '../../../../models/Article'
import { HtmlDiv } from '../../../../utils/types'
import Article from '../Article/Article'
import TableOfContents from '../../Navigation/TableOfContents/TableOfContents'
import colors from '../../../../models/Theme/ColorPallets'
import Section from '../../Layout/Section/Section'

const Manual = ({ article, className, ...rest }: ManualProps) => {
  return (
    <div className={` ${className}`} {...rest}>
      <Section className="relative flex flex-wrap flex-row-reverse justify-around">
        <div className="gutter relative lg:rounded-none rounded-2xl sm:block hidden my-8 lg:my-0 ">
          <TableOfContents
            className="font-bold text-light text-lg py-10 px-3 sticky sticky-offset"
            tableOfContents={article.tableOfContents}
          />
        </div>

        <Article className="article axiforma break-words" article={article} />
      </Section>
    </div>
  )
}

export interface ManualProps extends HtmlDiv {
  article: ArticleModel
}

export default styled(observer(Manual))`
  img {
    background: transparent;
  }

  .gutter {
    background: ${colors.gray[900]};
    width: 25rem;
    color: ${(props) => props.theme.text.colors.light} !important;
  }

  .article {
    max-width: 45rem;
  }
`
