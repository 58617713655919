import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv } from '../../../../utils/types'
import Layout from '../../Layout/Layout'
import Manual from '../../../Core/Content/Manual/Manual'
import Article from '../../../../models/Article'

const GameManualPage = ({
  manualArticle,
  className,
  ...rest
}: GameManualPageProps) => {
  return (
    <Layout
      className={`${className}`}
      {...rest}
      seoTitle="Game Docs"
      seoDescription="The source of truth for everything Dev Simulator"
    >
      <Manual article={manualArticle} />
    </Layout>
  )
}

export interface GameManualPageProps extends HtmlDiv {
  manualArticle: Article
}

export default styled(observer(GameManualPage))``
