import React from 'react'
import { graphql } from 'gatsby'
import { HtmlDiv } from '../utils/types'
import styled from 'styled-components'
import Article, { TableOfContents } from '../models/Article'
import GameManualPage from '../components/MarketingSite/Pages/GameManual/GameManualPage'

const DocsTemplate = ({ data }: DocsPageProps) => {
  const { body, tableOfContents } = data.mdx
  const article = new Article(body, 0, 0, tableOfContents)
  return <GameManualPage manualArticle={article} />
}

interface DocsPageProps extends HtmlDiv {
  data: { mdx: { body: string; tableOfContents: TableOfContents } }
}

export default styled(DocsTemplate)``

export const query = graphql`
  query DocsPageQuery {
    mdx(frontmatter: { slug: { eq: "docs" } }) {
      body
      tableOfContents
    }
  }
`
